@media (max-width: 1024px) {
  #Announcement {
    background-color: #055C9D;
    padding: 20px;
  }
  .mobilePad {
    padding-bottom: 15px;
  }
  #portOfficeName {
    padding-top: 60px;
  }
  .desktopOnly {
    display: none;
  }
  .pictureHeader {
    font-size: 55px;
    font-size: 13vw;
    position: absolute;
    top: 34%;
    top: 34vh;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .pictureText {
    display: flex;
    position: absolute;
    top: 46%;
    top: 46vh;
    padding: 0 10px;
    font-size: 25px;
    font-size: 6vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 1px 1px black;
    font-family:'Times New Roman', Times, serif;
  }
  #contFont {
    font-size: 43px !important;
    font-size: 13vw; 
  }
  .mobTitle {
    position: absolute;
    top: 20px;
    top: 2vh;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 350;
    text-shadow: 1px 1px black;
    padding: 20px;
  }
  .aboutPara {
    color: black;
    font-size: 22px;
    text-align: left;
    padding: 0 40px;
  }
  .aboutPic {
    width: 100%;
    border-radius: 10px;
  }
  #aroundOffice {
    padding: 80px 30px 50px 30px;
  }
  .officeName {
    text-align: center;
    padding: 30px 0 10px 0;
    font-size: 26px;
  }
  #paraDiv {
    margin-top: 60px;
  }
  .mobileMenu {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    overflow: auto;
    background-color: #055b9df1;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .slideBefore {
    bottom: 0;
  }
  .slideUp {
    bottom: 0;
  }
  .mobSocialIcon {
    color: white;
    font-size: 25px;
    margin: 0 10px;
    text-decoration: none;
  }
  #socialMobDiv {
    padding: 20px 40px;
  }
  #menuClose {
    font-size: 34px;
    position: absolute;
    top: 30px;
    right: 20px;
    color: white;
  }
  #mobNav {
    margin-top: 50px;
  }
  .mobileItem {
    font-size: 30px;
    color: white;
    font-weight: 300;
    padding: 20px 50px;
    text-align: left;
    text-decoration: none;
  }
  #mobNav {
    display: flex;
    flex-direction: column;
  }
  #divLogo {
    text-align: center;
  }
  #annCenter {
    display: block;
    text-align: center;
  }
  #navBarId {
    display: none;
  }
  #pageLogo {
    width: 50%;
    text-align: center;
    display: inline;
  }
  #menuButton {
    font-size: 6vw;
    position: relative;
    top: -0.23em;
    left: 1.9em;
  }
  .headerBar {
    padding: 21px;
    background-color: white;
    position: sticky;
    top: -1px;
    overflow: visible;
    z-index: 1;
  }
  #mainService {
    display: none;
  }
  #annDivRight {
    display: none;
  }
  #annDivLeft {
    display: none;
  }
  #visitFeat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px 0 5px;
    align-items: center;
  }
  .mooreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 15px 30px;
  }
  #mobileCenterTable {
    margin: 0 auto;
    padding: 30px 30px 60px 30px;
  }
  .footerClass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 30px 30px;
    margin: 0px;
    background-color: #055C9D;
  }
  .footMenu {
    padding: 50px 0;
  }
  #visitPics {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    width: 80%;
    padding-bottom: 30px;
  }
  #visitHeadMob {
    text-align: center;
    font-size: 50px;
    padding-top: 60px;
    font-family:'Times New Roman', Times, serif;
    color: white;
    font-weight: 400;
    text-shadow: 2px 2px rgb(63, 63, 63);
  }
  .noMobile {
    display: none;
  }
  .paddingLeft10 {
    display: none;
    text-align: center;
    padding: 20px;
  }
  #visitHead {
    display: none;
  }
  #permBook {
    padding: 15px 30px;
    font-size: 18px;
    text-decoration: none;
    color: white;
    background: #04386d;
    margin: 0 10px;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 1;
    border-radius: 15px;
    border: solid 1px white;
  }
  .bottomCover {
    display: none;
  }
  #visitInfo {
    padding: 0 30px;
    text-align: center;
  }
  #bookSectPara {
    font-size: 20px;
    padding: 20px 0 40px 0;
    line-height: 35px;
  }
  #onlineBook {
    background-image: linear-gradient(to bottom right, #003060, #29a2f3);
    padding: 80px 35px 80px 35px;
    text-align: center;
  }
  .mooreData {
    text-align: center;
  }
  #meetFont {
    font-size: 35px;
    padding-bottom: 10px;
  }
  .mooreText {
    font-size: 18px;
    color: #1B1212;
  }
  #secondPara {
    display: none;
  }
  .mooreLinkButton {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
  .mooreOffice {
    text-decoration: none;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: #003060;
    color: white;
    border: 1px solid #003060;
    border-radius: 13px;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  }
  .mooreOffice:hover {
    background-color: white;
    color: black;
    border: 1px black solid;
  }
  #insuranceHead {
    font-size: 42px;
    font-weight: 500;
    padding-bottom: 20px;
  }
  #insurance {
    font-size: 40px;
    text-align: center;
    padding: 40px 10px 40px 10px;
  }
  #sagicor {
    padding-bottom: 40px;
  }
  #guardian {
    padding-bottom: 80px;
  }
  .is-Flex-Row {
    padding: 50px 20px 0 20px;
  }
  .reviewBox {
    display: none;
  }
  #reviewH2 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
  }
  #revStars {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
  }
  #revGoogle {
    font-size: 18px;
    text-decoration: underline;
    color: black;
  }
  #revBox4 {
    display: none;
  }
  #reviewTitle {
    text-align: center;
    margin-bottom: 20px;
  }
  .carouselDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
  }
  #promiseBox {
    border: 1px solid white;
    border-radius: 0 45px 0 45px;
    padding: 30px 30px 60px 30px;
  }
  #promiseCont {
    background-color: #005481;
    padding: 25px;
  }
  .toothList {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  #promInfo {
    color: white;
  }
  #promHead {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
  }
  #promText {
    font-size: 20px;
    font-weight: 400;
  }
  #promPic {
    display: none;
  }
  #serviceGrid {
    display: none;
  }
  .servMobH2 {
    padding-bottom: 20px;
  }
  .rec-arrow {
    display: none;
  }
  .mobh3 {
    font-weight: 500;
    position: relative;
    bottom: 180px;
    color: white;
    text-shadow: 1px 1px black;
  }
  
  #servHead {
    font-size: 40px;
    color: black;
    padding-top: 20px;
    font-weight: 500;
    font-family:'Times New Roman', Times, serif;
  }
  .serviceH {
    padding-top: 30px;
  }
  .paraServ {
    color: black;
    font-size: 14px;
    text-align: left;
    padding: 0 20px;
  }
  .teamPara {
    color: black;
    font-size: 20px;
  }
  .teamPic {
    width: 100%;
  }
  .teamLabel {
    font-size: 24px;
    color: black;
    text-align: center;
    padding: 20px 0 30px 0;
  }
  .teamInfo {
    padding: 40px 40px 0 40px;
  }
  .teamDiv {
    margin-bottom: 100px;
  }
  .servicesPre {
    padding: 30px 40px;
  }
  #servicesh2 {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
  .servCarousel {
    padding: 30px 0;
  }
  .servPic {
    height: 300px;
  }
  #restDent {
    display: none !important;
  }
  #servBook {
    padding: 30px 0 50px 0;
  }
  #servBookButton {
    padding: 20px 20px;
  }
  .servBoxText {
    padding: 0 5px 20px 5px;
  }
  .servBox > img {
    height: 320px;
  }
  .servBox {
    background-color: white;
    width: 100%;
    max-width: 320px;
    height: 780px;
    border-radius: 15px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .contactSection {
    margin-top: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contactRight {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .inputField {
    padding: 14px 0px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 15px;
  }
  .contactSubmit {
    padding: 14px 0px;
    width: 100%;
    margin: 10px auto 30px auto;
    background-color: #003060;
    color: white;
    font-size: 16px;
    text-align: center;
    border: 1px solid #003060;
    border-radius: 10px;
  }
  .mobSched {
    text-decoration: none;
    padding: 13px;
    width: 80%;
    margin: auto;
    text-align: center;
    background-color: #003060;
    color: white;
    border: 1px solid #003060;
    border-radius: 13px;
    font-size: 18px;
    position: absolute;
    top: 250px;
    top: 26vh;
    left: 0;
    right: 0;
  }
  .mobCall {
    text-decoration: none;
    padding: 13px;
    width: 80%;
    margin: auto;
    text-align: center;
    background-color: #055C9D;
    color: white;
    border: 1px solid #055C9D;
    border-radius: 13px;
    font-size: 18px;
    position: absolute;
    top: 320px;
    top: 33vh;
    left: 0;
    right: 0;
  }
  .mobLoc {
    position: absolute;
    top: 180px;
    top: 50vh;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    text-shadow: 1px 1px black;
    padding: 20px;
    color: white;
  }
  #mobIc {
    font-size: 25px;
    margin-right: 5px;
  }
  .mobDarken {
    filter: brightness(60%);
  }
  .contactSection2 {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  #smileHeader {
    color: black;
    text-decoration: none;
    font-size: 2em;
    text-shadow: none;
    font-weight: 300;
    padding: 30px 0 10px 0;
    text-align: center;
    margin: 0;
  }
  #instafeed-container img {
    display: block;
    width: 85%;
    border-radius: 15px;
    margin: 20px auto;
  }
  #instafeed-container {
    text-align: center;
    padding: 0 0 40px 0;
  }
}
.conDisplay {
  display: none;
}
@media (min-width: 1024px) {
  #instafeed-container img {
    display: inline;
    max-height: 500px;
    border-radius: 15px;
    margin: 20px;
  }
  #pageLogo {
    width: 300px;
    margin: 20px 40px;
  }
  .zoomDesk {
    transition: transform 0.2s ease-out;
  }
  #instafeed-container {
    text-align: center;
    padding: 0 0 60px 0;
  }
  #smileHeader {
    color: black;
    text-decoration: none;
    font-size: 2.5em;
    text-shadow: none;
    font-weight: 300;
    padding: 40px 0 20px 0;
  }
  .contactSubmit {
    padding: 14px 0px;
    width: 100%;
    margin: 10px;
    background-color: #003060;
    color: white;
    font-size: 16px;
    text-align: center;
    border: 1px solid #003060;
    border-radius: 10px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  }
  .inputField {
    padding: 14px 0px;
    width: 100%;
    text-align: center;
    margin: 10px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 15px;
  }
  .contactRight {
    width: 35%;
    padding: 10px;
    text-align: center;
  }
  .contactSection, .contactSection2 {
    display: flex;
    padding: 80px 40px;
    gap: 200px;
    align-items: top;
    justify-content: center;
  }
  .contactForm {
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: center;
    width: 30%;
  }
  .servBoxText {
    padding: 0 20px 20px 20px;
  }
  .servBox > img {
    height: 320px;
  }
  .servContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .servBox {
    background-color: white;
    box-shadow: -1px 1px 6px rgb(37, 37, 37);
    width: 100%;
    max-width: 320px;
    border-radius: 15px;
  }
  .servPic {
    width: 100%;
    height: 21vh;
    border-radius: 15px 15px 0 0;
  }
  #servBook {
    padding: 50px 0 90px 0;
  }
  #servBookButton {
    padding: 20px 30px;
  }
  #servicesh2 {
    font-weight: 400;
    font-size: 33px;
  }
  .generalDent {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 40px 0px 40px 0px;
    gap: 40px;
  }
  .servicesPre {
    padding: 30px 60px;
  }
  .teamInfo {
    padding: 40px 70px;
  }
  .teamDiv {
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 30px;
  }
  .teamPic {
    max-width: 100%;
  }
  .teamLabel {
    font-size: 24px;
    color: black;
    text-align: center;
    padding: 20px 0 30px 0;
  }
  .teamPara {
    color: black;
    font-size: 20px;
    width: 70%;
  }
  .teamPlayer {
    width: 30%;
  }
  #aroundOffice {
    padding: 50px 70px 50px 70px;
  }
  .officeName {
    padding: 40px 40px 10px 40px;
    font-size: 26px;
  }
  .mobileOnly {
    display: none;
  }
  .aboutGrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 0 20px 40px 40px;
    margin: 0;
    gap: 30px;
  }
  .aboutPara {
    color: black;
    font-size: 22px;
    text-align: left;
    padding: 30px 400px;
  }
  .aboutPicGrid {
    background-color: white;
    box-shadow: -1px 1px 6px rgb(37, 37, 37);
    width: 100%;
    max-width: 330px;
    border-radius: 15px;
  }
  
  .aboutPic {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  #paraDiv {
    margin-top: 50px;
  }
  #insuranceProviders {
    display: flex;
    justify-content: center;
    gap: 130px;
    padding: 30px 20px;
  }
  .pictureHeader {
    font-size: 4em;
    position: absolute;
    top: 400px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .pictureText {
    display: flex;
    position: absolute;
    top: 540px;
    font-size: 1.5em;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 1px 1px black;
    font-family:'Times New Roman', Times, serif;
  }
  #menuButton {
    display: none;
  }
  .servGrid {
    background-color: white;
    box-shadow: -1px 1px 6px rgb(37, 37, 37);
    width: 100%;
    max-width: 330px;
    border-radius: 15px;
  }
  .h3Serve {
    padding: 0 20px;
    font-weight: 500;
    text-align: left;
  }
  
  #headBorder {
    border-bottom: 1px solid black;
  }
  
  #servHead {
    font-size: 40px;
    color: black;
    padding-top: 20px;
    font-weight: 500;
    font-family:'Times New Roman', Times, serif;
  }
  
  .paraServ {
    color: black;
    font-size: 14px;
    text-align: left;
    padding: 0 20px 10px 20px;
  }
  #promiseBox {
    border: 1px solid white;
    border-radius: 0 45px 0 45px;
    padding: 30px 30px 60px 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #promMobile {
    display: none;
  }
  #serviceGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 40px;
    gap: 30px;
  }
  .toothList {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
  #promiseCont {
    background-color: #005481;
    padding: 100px;
  }
  
  #promInfo {
    color: white;
    width: 40%;
  }
  
  #promHead {
    font-size: 50px;
    font-weight: 400;
    font-family:'Times New Roman', Times, serif;
  }
  
  #promText {
    font-size: 20px;
    font-weight: 400;
  }
  
  #promPic {
    width: 40%;
  }
  #reviewTitle {
    width: 30%;
    position: relative;
    top: -250px;
  }
  #slider {
    display: none;
  }
  .slideMobile {
    display: none;
  }
  #revGoogle {
    font-size: 20px;
    text-decoration: underline;
    color: black;
  }
  #revStars {
    margin-bottom: 25px;
    font-size: 20px;
  }
  #reviewH2 {
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  #reviews {
    width: 60%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px;
  }
  .reviewBox {
    padding: 30px;
    border-radius: 15px;
  }
  #insuranceHead {
    font-size: 42px;
    font-weight: 500;
  }
  #insurance {
    font-size: 40px;
    text-align: center;
    padding: 30px 30px 70px 30px;
  }
  #Announcement {
    display: flex;
    justify-content: space-between;
    background-color: #055C9D;
    padding: 20px;
  }
  .mooreOffice {
    text-decoration: none;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 40%;
    text-align: center;
    background-color: #003060;
    color: white;
    border: 1px solid #003060;
    border-radius: 13px;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  }
  
  .mooreOffice:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  .mooreLinkButton {
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
  }
  .mooreData {
    width: 40%;
  }
  .mooreText {
    font-size: 20px;
    color: #1B1212;
  }
  #meetFont {
    font-size: 50px;
  }
  #visitFeat {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    align-items: center;
  }
  .mooreContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 80px 120px;
    gap: 0px;
  }
  #visitHeadMob {
    display: none;
  }
  #onlineBook {
    background-image: linear-gradient(to bottom right, #003060, #29a2f3);
    padding: 80px;
    text-align: center;
  }
  #visitHead {
    font-size: 70px;
    font-family:'Times New Roman', Times, serif;
    color: white;
    font-weight: 400;
    text-shadow: 2px 2px rgb(63, 63, 63);
  }
  .footerClass {
    display: flex;
    justify-content: space-around;
    padding: 0 30px 30px 30px;
    margin: 0px;
    background-color: #055C9D;
  }
  .bottomCover {
    background-color: #055C9D;
    padding: 30px 0px;
    margin: 0px;
    position: absolute;
    bottom: 0px;
    width: inherit;
  }
  #visitPics {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    width: 25%;
  }
  .footMenu {
    padding: 20px;
    width: 25%
  }
  #permBook {
    display: none;
  }
  #annCenter {
    text-align: center;
    margin-right: 110px;
  }
  #annDivRight {
    margin-right: 20px;
  }
  #annDivLeft {
    margin-right: 20px;
  }
  .headerBar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    position: sticky;
    top: 0;
    overflow: visible;
    z-index: 1;
  }
  #visitInfo {
    width: 45%;
  }
  .paddingLeft10 {
    padding-left: 20px;
  }
  .is-Flex-Row {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    align-items: center;
  }
}
p {
  color: whitesmoke;
  margin: 0px;
}
.mapLink {
  text-decoration: none;
  color: white;
}
h2 {
  font-weight: 400;
  font-size: 30px;
}

h3 {
  font-weight: 400;
  font-size: 25px;
}
#bookSectPara {
  font-size: 20px;
  padding: 20px 0 40px 0;
  line-height: 35px;
}
#bookSectDiv {
  padding: 40px 0 50px 0;
}
.phone {
  color: white;
  text-decoration: none;
  padding: 5px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 7px 11px;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.2s, color 0.2s;
}

.phone:hover {
  background-color: white;
  color: black;
}

.footerIcon {
  padding: 10px;
  font-size: 20px;
}

.socialIcon {
  color: white;
  font-size: 23px;
  margin: 0 10px;
  text-decoration: none;
}

#navBarId {
  padding-top: 50px;
  margin-right: 40px;
}

.onlyCenter {
  text-align: center;
}

.headMenu {
  padding: 20px;
  text-decoration: none;
  color: black;
  font-size: 20px;
  transition: color 0.2s, border-color 0.2s, border-bottom 0.08s;
}

.bookNow {
  padding: 10px 20px;
  font-size: 20px;
  text-decoration: none;
  color: white;
  background: #003060;
  margin: 0 10px;
  border: 1px solid #003060;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.bookNow:hover {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.headMenu:hover {
  border-bottom: 1px solid black;
  color: #003060;
}

.fullPhoto {
  width: 100%;
}

.zoom {
  transition: transform 0.2s ease-out;
}

.zoom:hover {
  transform: scale(1.1);
}

.fullScreenPic {
  position: relative;
  width: 100%;
}

.embolden {
  font-weight: 600;
}

.font20 {
  font-size: 20px;
}

.whiteColor {
  color: white;
}

.mapIcon {
  margin: 0 10px;
}

.locationLink {
  color: white;
  text-decoration: none;
  margin-right: 10px;
}

.h3Head {
  font-size: 30px;
  color: white;
  font-weight: 1000;
}

.footLink {
  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 6px;
}

.footLink:hover {
  font-weight: bold;
}

.footNav {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.rightsSection {
  background-color: #003060;
  text-align: center;
  padding: 20px;
}

.phoneNumber {
  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 6px;
}

.active-link {
  color: #003060;
  border-bottom: 1px solid black;
}

.onlineBook {
  background-image: linear-gradient(to bottom right, #003060, #29a2f3);
  padding: 80px;
  text-align: center;
}

.bookHead {
  font-size: 40px;
  padding-bottom: 14px;
  text-align: center;
  color: white;
  font-family:'Times New Roman', Times, serif;
}

.theFonts {
  font-family:'Times New Roman', Times, serif;
}

.moorePort {
  width: 100%;
  text-align: center;

}

.darkenCover {
  filter: brightness(60%);
}

.lighten {
  filter: brightness(140%);
}

.paraTextClass {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px black;
  font-family:'Times New Roman', Times, serif;
}

.contactSubmit:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  cursor: pointer;
}

.fullWidth {
  width: 100%;
}

.contacth3 {
  font-size: 30px;
  color: #1B1212;
  font-family:'Times New Roman', Times, serif;
  font-weight: 300;
}

#sentConfirm {
  color: black;
  margin-top: 15px;
  padding: 10px;
  font-size: 20px;
}

#grid1 {
  border-radius: 20px 0 0 0;
}

#grid2 {
  border-radius: 0 20px 0 0;
}

#grid3 {
  border-radius: 0 0 0 20px;
}

#grid4 {
  border-radius: 0 0 20px 0;
}

#visitDiv {
  background-image: linear-gradient(to top, #5e94ad, #003060);
}

.darkWhiteText {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-family:'Times New Roman', Times, serif;
  font-weight: 400;
}

.paddingRight20 {
  padding-right: 20px;
}

.is-Flex-Col {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.underline {
  text-decoration: underline;
}

.mapLinkButton {
  padding: 10px;
  background-color: #003060;
  color: white;
  text-decoration: none;
  border-radius: 12px;
  font-size: 23px;
}

th, td {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 22px;
}

.inline {
  display: inline;
}

table {
  padding: 50px 20px 20px 20px;
}

#revBox1 {
  background-color: #80f7a8;
}

#revBox2 {
  background-color: #cbf780;
}

#revBox3 {
  background-color: #89c8f8;
}

#revBox4 {
  background-color: #d6a1ff;
}

hr {
  width: 20%;
  border: 1px solid black;
}

.black {
  color: black;
  font-size: 18px;
  font-weight: 400;
}

.paddingBottom {
  padding-bottom: 20px;
}

.paddingTop {
  padding-top: 20px;
}

.alignTop {
  align-items: top;
}

li {
  padding: 7px 0;
}

.marginCenter {
  margin: 0 auto;
}

.promiseList {
  list-style: none;
}

#mainService1 {
  padding: 20px;
  margin-bottom: 50px;
}

#contactImg {
  width: 80%;
}
.pictureDivText {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px black;
  font-family:'Times New Roman', Times, serif;
}

.hyperLink {
  color: #0856a4;
}

.hyperLink:hover {
  color: #2c70b4;
}

.servicesH3 {
  font-size: 30px;
  padding-top: 20px;
}
#restDent {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 40px 0px;
  gap: 0px;
  justify-items: center;
}
.servicesPara {
  color: black;
  font-size: 22px;
}
#bookButtonLayout {
  display: flex;
  gap: 20px;
  margin: 0px;
  justify-content: space-around;

}
.bookButtonColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}
.citiBookButton {
  padding: 18px;
  margin: 10px;
  width: 90%;
  color: white;
  background-color: #003060;
  border-radius: 15px;
  border: 1px solid #003060;
  font-size: 16px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}
.citiBookButton:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
}
.overarchingDiv {
  margin: 20px 0;
}
.appHead {
  padding: 20px 50px;
}
.appExtra {
  padding-top: 60px;
}
#patientStatus {
  text-align: center;
  padding: 0 50px;
}
.citiBookButton2 {
  padding: 18px;
  margin-bottom: 20px;
  width: 100%;
  color: white;
  background-color: #003060;
  border-radius: 15px;
  border: 1px solid #003060;
  font-size: 16px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}
.citiBookButton2:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
}
.patientSect {
  display: none;
}
.patientSectActive {
  display: block;
}
.appTypeDiv {
  padding: 30px 40px 60px 40px;
}
.appTypeNone {
  display: none;
}
.locInactive {
  display: none;
}
.locActive {
  display: block;
}
#textbox {
  padding: 10px 0 150px 0;
  font-family: Arial, Helvetica, sans-serif;
}
textarea {
  resize: none;
}